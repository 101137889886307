import { useQuery } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

import { directAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { searchSuggestionsURL } from '@/constants/apiURLs'
import { type SearchSuggestion, SearchSuggestionType } from '@/interfaces/autocompleteSearchOption'

const useSearchSuggestions = () => {
  const [searchTerm, setSearchTerm] = useState<string>()

  const searchSuggestionsFn = useCallback(async (): Promise<SearchSuggestion[]> => {
    return (
      directAPIRequest([RequestMethods.GET], searchSuggestionsURL(searchTerm!), false, {
        method: RequestMethods.GET,
      }) as Promise<{ matches: string[] }>
    ).then((data) => {
      return data?.matches.map((match: string) => {
        return {
          title: match,
          type: SearchSuggestionType.AUTOCOMPLETION,
          params: { searchTerm: match },
        } as SearchSuggestion
      })
    })
  }, [searchTerm])

  const { data: searchSuggestions } = useQuery({
    queryKey: ['searchSuggestions', searchTerm],
    queryFn: searchSuggestionsFn,
    enabled: !!searchTerm && searchTerm.length > 2,
  })

  return { searchSuggestions, setSuggestionSearchTerm: setSearchTerm }
}

export default useSearchSuggestions
