import type { PromotionFilterParams } from '@/interfaces/promotions/promotion'
import type { SearchParams } from '@/interfaces/searchRequest'

import URI from 'urijs'

import { getAddCreditUri, getSellersHubHref } from './dashboard'

export const SEARCH_BASE_HREF = '/search'

export const purchaseHistoryHref = '/user/dashboard/buyers-hub/purchase-history'

export const salesHistoryHref = '/user/dashboard/sellers-hub/all-sales-history'

export const productHref = (productId: number, gradeId: number) => {
  return `/product?productId=${productId}&gradeId=${gradeId}`
}

export const auctionHref = (auctionId: number) => {
  return `/product?auctionId=${auctionId}`
}

export const itemHref = (itemId: number) => {
  return `/item/${itemId}`
}

export const ownerHref = (owner = '') => {
  return `/search?ownerName=${encodeURIComponent(owner)}`
}

export const searchSetHref = (
  category = '',
  decadePath = '',
  yearPath = '',
  setPath = '',
  parentSetPath = '',
  setName = ''
): string => {
  return new URI(`/search`)
    .query({
      category,
      decadePath,
      yearPath,
      setPath,
      parentSetPath,
      searchTerm: !(category && decadePath && yearPath && setPath && parentSetPath) ? setName : undefined,
    })
    .toString()
}

export const searchHref = (searchParams: Partial<SearchParams> & { recentSearchID?: string | null }): string => {
  return new URI(`/search`).query(searchParams).toString()
}

export const addCreditHref = (amountNeeded?: number, additionalQuery?: string | URI.QueryDataMap): string => {
  let uri = getAddCreditUri()
  if (amountNeeded) {
    uri = uri.addQuery({ queryAmount: amountNeeded })
  }
  if (additionalQuery) {
    uri = uri.addQuery(additionalQuery)
  }
  return uri.toString()
}

export const sellersHubHref = getSellersHubHref()

export const cartHref = '/cart'

export const promotionsHref = (filterParams: PromotionFilterParams) => {
  const uri = new URI('/promotions')
  uri.addQuery(filterParams)
  return uri.toString()
}
