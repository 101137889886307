import type { SearchResultsData } from '@/interfaces/searchResult'
import type { SearchParams } from '@/interfaces/searchRequest'
import type { AvailabilityOptions, ListingFormatOptions } from '@/interfaces/filters'

import { createContext, type PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react'
import { parseAsInteger, useQueryStates, parseAsString, parseAsStringLiteral, useQueryState } from 'nuqs'

import { ITEM_SORT } from '@/constants/sort'
import { DEFAULT_SEARCH_REQUEST_VALUES } from '@/constants'
import useSearch from '@/hooks/data/useSearch'
import { resultParamsToQueryParams } from '@/helpers'

type SearchResultsContext = {
  recentSearchID: string | null
  searchResults?: SearchResultsData
  searchResultsLoading: boolean
  searchParams: SearchParams
  updateSearchParams: (newSearchParams: SearchParams) => void
}

const searchResultsContext = createContext<SearchResultsContext>({
  recentSearchID: null,
  searchResults: undefined,
  searchResultsLoading: false,
  searchParams: DEFAULT_SEARCH_REQUEST_VALUES(),
  updateSearchParams: () => {},
})

export interface SearchResultsProviderProps extends PropsWithChildren {
  initialSearchParams: SearchParams
}

export default function SearchResultsProvider({
  initialSearchParams,
  children,
}: SearchResultsProviderProps): JSX.Element {
  const initialParamsWithDefaults = useMemo(() => {
    return {
      ...DEFAULT_SEARCH_REQUEST_VALUES(true),
      ...initialSearchParams,
    }
  }, [initialSearchParams])

  const [recentSearchID, setRecentSearchID] = useQueryState('recentSearchID')

  const [searchUrlParams, setSearchUrlParams] = useQueryStates(
    {
      searchTerm: parseAsString.withDefault(initialParamsWithDefaults.searchTerm),
      sort: parseAsStringLiteral(Object.values(ITEM_SORT)).withDefault(initialParamsWithDefaults.sort),
      pageNum: parseAsInteger.withDefault(initialParamsWithDefaults.pageNum),
      attributePaths: parseAsString.withDefault(initialParamsWithDefaults.attributePaths || ''),
      availability: parseAsStringLiteral(['Ignore', 'Include', 'OnlySoldOut'] as AvailabilityOptions[]).withDefault(
        initialParamsWithDefaults.availability
      ),
      listingFormat: parseAsStringLiteral(['All', 'Auction', 'BuyNow'] as ListingFormatOptions[]).withDefault(
        initialParamsWithDefaults.listingFormat
      ),
      playerId: parseAsInteger.withDefault(initialParamsWithDefaults.playerId!),
      teamId: parseAsInteger.withDefault(initialParamsWithDefaults.teamId!),
      ownerName: parseAsString.withDefault(initialParamsWithDefaults.ownerName!),
      sportPath: parseAsString.withDefault(initialParamsWithDefaults.sportPath!),
      gradeActionPath: parseAsString.withDefault(initialParamsWithDefaults.gradeActionPath!),
      graderPath: parseAsString.withDefault(initialParamsWithDefaults.graderPath!),
      gradePath: parseAsString.withDefault(initialParamsWithDefaults.gradePath!),
      decadePath: parseAsString.withDefault(initialParamsWithDefaults.decadePath!),
      yearPath: parseAsString.withDefault(initialParamsWithDefaults.yearPath!),
      parentSetPath: parseAsString.withDefault(initialParamsWithDefaults.parentSetPath!),
      setPath: parseAsString.withDefault(initialParamsWithDefaults.setPath!),
      subSearch: parseAsString.withDefault(initialParamsWithDefaults.subSearch!),
    },
    {
      history: 'push',
    }
  )

  const searchParams = useMemo(
    () => ({
      ...DEFAULT_SEARCH_REQUEST_VALUES(),
      ...searchUrlParams,
    }),
    [searchUrlParams]
  )

  const { searchResults, searchResultsLoading } = useSearch({
    searchParams,
    recentSearchID: recentSearchID ? parseInt(recentSearchID) : null,
  })

  // // Update query params to the parameters returned from recent search API request
  useEffect(() => {
    if (recentSearchID && searchResults?.parameters) {
      setSearchUrlParams(resultParamsToQueryParams(searchResults.parameters), { history: 'replace' })
      setRecentSearchID(null)
    }
  }, [recentSearchID, searchResults?.parameters, setRecentSearchID, setSearchUrlParams])

  // Called from various components to update params, ex. filters
  const updateSearchParams = useCallback(
    (newParams: SearchParams) => {
      if (!newParams) return

      setRecentSearchID(null)
      setSearchUrlParams(newParams)
    },
    [setRecentSearchID, setSearchUrlParams]
  )

  return (
    <searchResultsContext.Provider
      value={{
        recentSearchID,
        searchParams,
        updateSearchParams,
        searchResults,
        searchResultsLoading,
      }}
    >
      {children}
    </searchResultsContext.Provider>
  )
}

export const useSearchResultsProvider = (): SearchResultsContext => useContext(searchResultsContext)
