import type { SearchParams } from '@/interfaces/searchRequest'

import { ChevronRight } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import {
  Autocomplete,
  autocompleteClasses,
  type AutocompleteProps,
  Box,
  Button,
  type CSSInterpolation,
  Divider,
  FormControl,
  InputAdornment,
  inputBaseClasses,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { has, isNil } from 'lodash'
import { useRouter } from 'next/router'
import { type SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Key } from 'ts-key-enum'
import { useQueryState } from 'nuqs'

import { DEFAULT_SEARCH_REQUEST_VALUES, searchHref, STRINGS } from '@/constants'
import { isBlank, isNotBlank } from '@/helpers'
import SearchBarAutocompleteOption from './SearchBarAutocompleteOption'
import { useSavedSearchesProvider } from '@/store/savedSearchesProvider'
import Link from '@/components/ui/Link'
import { DashboardPageKeys, DashboardSections, getDashboardRouteByKeysOrDefault } from '@/constants/dashboard'
import { searchTermEquals } from '@/helpers/savedSearches'
import { type SearchSuggestion, SearchSuggestionType } from '@/interfaces/autocompleteSearchOption'
import { SavedSearchUtils } from '@/interfaces/savedSearches/savedSearches'
import useSearchSuggestions from '@/hooks/data/useSearchSuggestions'
import useRecentSearches from '@/hooks/data/useRecentSearches'

function isSearchSuggestion(option: string | SearchSuggestion | null | undefined): option is SearchSuggestion {
  return (
    !isNil(option) && typeof option !== 'string' && has(option, 'title') && has(option, 'params') && has(option, 'type')
  )
}

function getSearchSuggestionType(group: string) {
  const key = group as keyof typeof SearchSuggestionType
  return SearchSuggestionType[key] || SearchSuggestionType.SEARCH
}

/** Get suggestion group label */
function getGroupLabel(group: string) {
  const suggestionType = getSearchSuggestionType(group)
  switch (suggestionType) {
    case SearchSuggestionType.SAVED_SEARCH:
      return STRINGS.SAVED_SEARCHES_LABEL
    case SearchSuggestionType.RECENT_SEARCH:
      return STRINGS.RECENT_SEARCHES_LABEL
    case SearchSuggestionType.AUTOCOMPLETION:
      return STRINGS.RESULTS_LABEL
    default:
      return ''
  }
}

function SearchBar() {
  const MAX_SAVED_SEARCH_SUGGESTIONS = 5

  const router = useRouter()
  const { isSavedSearchesLoading, getSavedSearch, getSavedSearchSuggestions } = useSavedSearchesProvider()
  const { setSuggestionSearchTerm, searchSuggestions } = useSearchSuggestions()

  const [searchTerm] = useQueryState('searchTerm')
  const { recentSearches } = useRecentSearches(searchTerm || '')

  const [enteredSearchTerm, setEnteredSearchTerm] = useState(searchTerm)
  const [autocompleteOption, setAutocompleteOption] = useState<SearchSuggestion | null>(null)
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [savedSearchSuggestions, setSavedSearchSuggestions] = useState<SearchSuggestion[]>([])
  const [showSeeMoreSavedSearchesLink, setShowSeeMoreSavedSearchesLink] = useState<boolean>(false)
  const [inputFocus, setInputFocus] = useState<boolean>(false)

  const showSearchSuggestions = enteredSearchTerm && enteredSearchTerm.length > 2

  const hasOptions = useMemo(() => {
    if (showSearchSuggestions) {
      return !!(searchSuggestions && searchSuggestions.length > 0)
    }

    return savedSearchSuggestions.length > 0 || !!(recentSearches && recentSearches.length > 0)
  }, [recentSearches, savedSearchSuggestions.length, searchSuggestions, showSearchSuggestions])

  /** Used for id and key */
  const autocompleteKey = 'header-search-autocomplete'

  const handleSubmit = (event: SyntheticEvent, value?: SearchSuggestion | string | null) => {
    if (event) {
      event.preventDefault()
    }
    setAutocompleteOpen(false)

    const defaultSearchParams: SearchParams = DEFAULT_SEARCH_REQUEST_VALUES()

    // Default search if value is null, undefined, ''
    if (!value) {
      router.push(searchHref({ searchTerm: defaultSearchParams.searchTerm }))
      return
    }

    // If string, it's just a simple search term
    if (typeof value === 'string') {
      const href = searchHref({ searchTerm: value })
      searchTerm === value ? router.replace(href) : router.push(href)
      return
    }

    // Otherwise handle saved/recent search
    switch (value.type) {
      case SearchSuggestionType.RECENT_SEARCH:
        router.push(searchHref({ recentSearchID: value.id?.toString() || null }))
        break
      case SearchSuggestionType.SAVED_SEARCH:
        router.push(SavedSearchUtils.toSearchUrl(getSavedSearch(value.id)))
        break
      default:
        router.push(searchHref({ ...defaultSearchParams, ...value.params }))
        break
    }
  }

  /** Find suggestion based on provided search term */
  const getSuggestion = useCallback(
    (value?: string) => {
      return searchSuggestions && searchSuggestions.find((opt) => searchTermEquals(opt.params.searchTerm, value))
    },
    [searchSuggestions]
  )

  const closeAndUnfocus = () => {
    setAutocompleteOpen(false)
    setInputFocus(false)
  }

  const handleSetEnteredSearchTerm = useCallback(
    (value: string, options: { autocomplete: boolean } = { autocomplete: true }) => {
      setEnteredSearchTerm(value)
      if (options?.autocomplete && isNotBlank(value)) {
        setSuggestionSearchTerm(value)
      }
    },
    [setSuggestionSearchTerm]
  )

  /** Autocomplete event handlers and styles */
  const autocompleteProps = {
    options: [],
    filterOptions: (options) => {
      const suggestions = searchSuggestions || options.filter((opt) => opt.type === SearchSuggestionType.AUTOCOMPLETION)
      if (enteredSearchTerm && enteredSearchTerm.length > 2) {
        return Array.from(new Set([...suggestions]))
      } else {
        return [...(recentSearches || []), ...savedSearchSuggestions]
      }
    },
    getOptionLabel: (option) => {
      if (isSearchSuggestion(option)) {
        return option.params.searchTerm || ''
      } else {
        return option || ''
      }
    },
    groupBy: (option) => {
      switch (option.type) {
        case SearchSuggestionType.SAVED_SEARCH:
        case SearchSuggestionType.RECENT_SEARCH:
        case SearchSuggestionType.AUTOCOMPLETION:
          return SearchSuggestionType[option.type]
        default:
          return SearchSuggestionType.SEARCH
      }
    },
    isOptionEqualToValue: (option, value) => {
      const sameId = (option.id || value.id) && option.id === value.id
      const sameTitle =
        searchTermEquals(option.params.searchTerm, value.params.searchTerm) ||
        searchTermEquals(option.title, value.title)
      return sameId || sameTitle
    },
    onChange: (event, value, reason) => {
      let suggestion: SearchSuggestion | null = null
      if (!isNil(value)) {
        if (isSearchSuggestion(value)) {
          suggestion = value
        }
        if (typeof value === 'string') {
          suggestion = getSuggestion(value) || null
          if (!suggestion) {
            suggestion = {
              title: value,
              type: SearchSuggestionType.SEARCH,
              params: { searchTerm: value.trim() },
            }
          }
        }
      }

      switch (reason) {
        case 'clear':
          setAutocompleteOption(null)
          break
        case 'selectOption':
          setAutocompleteOption(suggestion)
          handleSubmit(event, suggestion)
          break
        case 'createOption':
          setAutocompleteOption(suggestion)
          handleSubmit(event, suggestion)
          break
        case 'removeOption':
        case 'blur':
        default:
          break
      }
    },
    onInputChange: (event, value, reason) => {
      switch (reason) {
        case 'clear':
          handleSetEnteredSearchTerm('')
          break
        case 'input':
          handleSetEnteredSearchTerm(value)
          break
        case 'reset':
        default:
          break
      }
    },
    renderInput: (params) => {
      const endAdornment = (
        <>
          {params.InputProps.endAdornment}
          <Divider sx={{ ml: 0.5 }} orientation="vertical" flexItem />
          <InputAdornment position="end">
            <Button
              color="primary"
              variant="contained"
              aria-label="search collectibles"
              endIcon={<SearchIcon />}
              onClick={(event) => {
                event.stopPropagation()
                handleSubmit(event, enteredSearchTerm)
              }}
            >
              Go
            </Button>
          </InputAdornment>
        </>
      )
      return (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          color="primary"
          autoComplete="off"
          placeholder="Search for collectibles"
          focused={inputFocus}
          onBlur={() => {
            setInputFocus(false)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleSubmit(event, enteredSearchTerm)
            }
          }}
          onFocus={() => {
            setInputFocus(true)
          }}
          InputProps={{
            ...params.InputProps,
            'aria-label': 'search for collectibles',
            type: 'search',
            endAdornment,
          }}
        />
      )
    },
    renderGroup: (params) => {
      const suggestionType = getSearchSuggestionType(params.group)
      const label = getGroupLabel(params.group)
      const isSavedSearch = suggestionType === SearchSuggestionType.SAVED_SEARCH
      const seeMoreLink = getDashboardRouteByKeysOrDefault(
        DashboardSections.WATCHING,
        DashboardPageKeys.WATCHING.SAVED_SEARCHES
      ).pathname

      return (
        <li key={params.key} className={`group ${params.group}`}>
          <Stack className={autocompleteClasses.groupLabel}>
            <Typography className="label">{label}</Typography>
            {isSavedSearch && showSeeMoreSavedSearchesLink && (
              <Link href={seeMoreLink} className="see-more" onClick={closeAndUnfocus}>
                <Typography component="span">{STRINGS.SEE_MORE_ACTION}</Typography>
                <ChevronRight color="mediumGrey" />
              </Link>
            )}
          </Stack>
          <ul className={autocompleteClasses.groupUl}>{params.children}</ul>
        </li>
      )
    },
    renderOption: (props, option, state) => {
      const id = `${option.id || ''}${option.title}`
      return <SearchBarAutocompleteOption {...{ props, option, state }} key={id} />
    },
    // Autocomplete styles
    slotProps: {
      paper: {
        className: `${!hasOptions ? autocompleteClasses.noOptions : ''}`,
        sx: (theme) => {
          const backgroundColor = theme.palette.background.paper
          const textColor = theme.palette.mediumGrey.main
          const gap = '14px'

          const flexRow: CSSInterpolation = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }

          const flexColumn: CSSInterpolation = {
            display: 'flex',
            flexDirection: 'column',
            gap,
          }
          const listContentPadding: CSSInterpolation = {
            padding: '0 20px',
          }

          return {
            backgroundColor,
            color: textColor,
            padding: '20px 0',
            [`&.${autocompleteClasses.noOptions}`]: {
              py: 0,
              '&::after': {
                content: '"No suggestions"',
                display: 'block',
                padding: '1em',
                color: 'mediumGrey.main',
              },
            },
            [`.${autocompleteClasses.listbox}`]: {
              ...flexColumn,
              alignItems: 'flex-start',
              padding: 0,
              '& li': {
                width: '100%',
              },
              '& > li': {
                ...flexColumn,
              },
              [`& .${autocompleteClasses.groupLabel}`]: {
                ...flexRow,
                ...listContentPadding,
                backgroundColor,
                justifyContent: 'space-between',
                position: 'sticky',
                top: 0,
                '& , & *': {
                  fontWeight: 700,
                },
                '& a.see-more': {
                  ...flexRow,
                  textDecoration: 'none',
                  gap: 0,
                  color: theme.palette.linkBlue.main,
                },
              },
              [`& .${autocompleteClasses.groupUl}`]: {
                ...flexColumn,
                alignItems: 'flex-start',
                padding: 0,
              },
              [`& .${autocompleteClasses.option}`]: {
                ...flexRow,
                ...listContentPadding,
                margin: 0,
                justifyContent: 'space-between',
                '& .label': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
                '& .highlight': {
                  color: 'black',
                  fontWeight: 700,
                },
                [`.${autocompleteClasses.clearIndicator}`]: {
                  py: '2px',
                },
              },
              [`& .group.${SearchSuggestionType.SAVED_SEARCH}`]: {
                [`.${autocompleteClasses.option}`]: {
                  display: 'grid',
                  gridTemplate: '1fr / auto 1fr auto',
                  justifyContent: 'start',
                  gap: '0.5em',
                },
              },
            },
          }
        },
      },
      clearIndicator: {
        sx: {
          position: 'relative',
          visibility: 'visible',
        },
      },
    },
    sx: () => {
      return {
        [`& .${autocompleteClasses.endAdornment}`]: {
          display: 'none',
        },
        [`&  .${inputBaseClasses.adornedEnd}`]: {
          paddingRight: '1em !important',
        },
      }
    },
    // Note: if you change the freeSolo, multiple, or disableClearable props, this type needs to updated
  } as AutocompleteProps<SearchSuggestion, false, false, true>

  useEffect(() => {
    handleSetEnteredSearchTerm(searchTerm || '', { autocomplete: false })
  }, [handleSetEnteredSearchTerm, searchTerm])

  /**
   * Load saved search suggestions
   */
  useMemo(() => {
    if (!isSavedSearchesLoading) {
      const suggestions = getSavedSearchSuggestions(0, MAX_SAVED_SEARCH_SUGGESTIONS + 1)
      setShowSeeMoreSavedSearchesLink(suggestions.length > MAX_SAVED_SEARCH_SUGGESTIONS)
      setSavedSearchSuggestions(suggestions.slice(0, MAX_SAVED_SEARCH_SUGGESTIONS))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavedSearchesLoading])

  return (
    <Box component="form" sx={{ m: { md: 1 }, px: { xs: 1, md: 0 }, width: { xs: '100%', md: '60%' } }} noValidate>
      <FormControl fullWidth={true} color="primary">
        <InputLabel htmlFor={autocompleteKey} sx={visuallyHidden}>
          {STRINGS.SEARCH_COMC_ITEMS_LABEL}
        </InputLabel>
        <Autocomplete
          key={autocompleteKey}
          id={autocompleteKey}
          /** freeSolo must not take a value */
          freeSolo
          multiple={false}
          disableClearable={false}
          /** Controls value */
          onChange={autocompleteProps.onChange}
          value={autocompleteOption}
          /** Controls inputValue */
          onInputChange={autocompleteProps.onInputChange}
          inputValue={enteredSearchTerm || ''}
          options={showSearchSuggestions && searchSuggestions ? searchSuggestions : []}
          open={autocompleteOpen}
          onOpen={() => {
            setAutocompleteOpen(true)
          }}
          onClose={() => {
            setAutocompleteOpen(false)
          }}
          onHighlightChange={(event, option) => {
            // Clear selected autocompleteOption if it does not match the current search term
            if (option && !searchTermEquals(option.params.searchTerm, enteredSearchTerm)) {
              setAutocompleteOption(null)
            }
          }}
          onKeyDown={(event) => {
            switch (event.key) {
              case Key.Enter:
                setAutocompleteOpen(false)
                break
              case Key.Escape:
                // Blur the input if the user hits escape twice
                if (!autocompleteOpen && isBlank(enteredSearchTerm)) {
                  // Note: this will trigger the onInputChange event handler
                  const target = event.target as HTMLInputElement
                  target.blur()
                }
                break
            }
          }}
          blurOnSelect
          clearOnBlur={false}
          openOnFocus
          selectOnFocus={false}
          filterOptions={autocompleteProps.filterOptions}
          getOptionLabel={autocompleteProps.getOptionLabel}
          groupBy={autocompleteProps.groupBy}
          isOptionEqualToValue={autocompleteProps.isOptionEqualToValue}
          /** Rendering & Styles */
          renderGroup={autocompleteProps.renderGroup}
          renderOption={autocompleteProps.renderOption}
          renderInput={autocompleteProps.renderInput}
          slotProps={autocompleteProps.slotProps}
          sx={autocompleteProps.sx}
        />
      </FormControl>
    </Box>
  )
}

export default SearchBar
