import type { SearchParams } from '@/interfaces/searchRequest'
import type { SearchResultsData } from '@/interfaces/searchResult'

import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { isEqual } from 'lodash'

import { clientAPIRequest, directAPIRequest, resultParamsToQueryParams } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'
import { recentSearchesURL } from '@/constants/apiURLs'

export interface UseSearchProps {
  searchParams: SearchParams
  recentSearchID: number | null
  staleTimeSeconds?: number
}

const useSearch = ({ searchParams, recentSearchID = null, staleTimeSeconds = 60 * 5 }: UseSearchProps) => {
  const { data: session } = useSession()
  const router = useRouter()

  const [recentSearchQueriedParams, setRecentSearchQueriedParams] = useState<SearchParams | null>(null)

  const {
    data: searchResults,
    isFetching: searchResultsLoading,
    ...props
  } = useQuery({
    queryKey: ['searchResults', recentSearchID, searchParams],
    queryFn: async () => {
      if (recentSearchID) {
        const data = await directAPIRequest<SearchResultsData>(
          [RequestMethods.GET],
          recentSearchesURL(recentSearchID),
          true,
          {
            method: RequestMethods.GET,
            session,
          }
        )
        setRecentSearchQueriedParams(resultParamsToQueryParams(data.parameters))
        return data
      }

      setRecentSearchQueriedParams(null)
      return clientAPIRequest<SearchResultsData, unknown>('/api/search', RequestMethods.GET, searchParams, session)
    },
    placeholderData: (prev: SearchResultsData | undefined) => prev,
    refetchOnWindowFocus: false,
    staleTime: 1000 * staleTimeSeconds,
    enabled: (!!recentSearchID || !isEqual(recentSearchQueriedParams, searchParams)) && router.isReady,
  })

  return { searchResults, searchResultsLoading, ...props }
}

export default useSearch
